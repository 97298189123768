const allChartTypes= {
    /* 单折线图 */
    axisOption:{
        color: ['#2684FC', '#F26A2F'],
        tooltip: {
            trigger: "axis",
            //formatter: "{a} <br/>{b} : {c}"
        },
        legend: {
            x: 'center',
            data: ["收益金额"]
        },
        grid: {//设置4周吴留白
            left: 0,
            bottom: 0,
            containLabel: true // 确保坐标轴标签也在grid区域内
        },
        xAxis: [
            {
                type: "category",
                name: "月份",
                splitLine: {show: false},
                data: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "1七", "1八", "1九"]
            }
        ],
        yAxis: [
            {
                type: "value",
                name: "数值"
            }
        ],
        calculable: true,
        series: [
            {
                name: "收益金额",
                type: "line",
                data: [1, 3, 9, 27, 81, 247, 741, 2223, 6669, 741, 2223, 6669]

            }
        ]
    },
    /* 双折线图 */
    axisOptions:{
        color: ['#2684FC', '#F26A2F'],
        tooltip: {
            trigger: "axis",
            //formatter: "{a} <br/>{b} : {c}"
        },
        legend: {
            x: 'center',
            data: ["交易订单数", "交易金额"]
        },
       grid: {//设置4周吴留白
           left: 0,
           bottom: 0,
           containLabel: true // 确保坐标轴标签也在grid区域内
       },
        xAxis: [
            {
                type: "category",
                name: "月份",
                splitLine: {show: false},
                data: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "1七", "1八", "1九"]
            }
        ],
        yAxis: [
            {
                type: "value",
                name: "数值"
            }
        ],
        calculable: true,
        series: [
            {
                name: "交易订单数",
                type: "line",
                data: [1, 3, 9, 27, 81, 247, 741, 2223, 6669, 741, 2223, 6669]

            },
            {
                name: "交易金额",
                type: "line",
                data: [1, 2, 4, 8, 16, 32, 64, 128, 256, 64, 128, 256]

            }
        ]
    },
    /* 地图 */
    mapOption:{
       tooltip : {
           trigger: 'item',
           formatter: '{b}：{c}'
       },
       grid: {//设置4周吴留白
           left: 0,
           right: 0,
           top: 0,
           bottom: 0,
           containLabel: true // 确保坐标轴标签也在grid区域内
       },
       dataRange: {
           min: 0,
           max: 20,
           color:['orange','yellow'],
           text:['高','低'],           // 文本，默认为数值文本
           calculable : true
       },
       series : [
           {
               name: '四川地图',
               type: 'map',
               mapType: '四川',
               roam: true,
               selectedMode : 'single',
               itemStyle:{
                   normal:{label:{show:true}},
                   emphasis:{label:{show:true}}
               },
               data:[
                   {name:'成都市',value:12},
                   {name:'眉山市',value:18},
                   {name:'德阳市',value:10},
                   {name:'绵阳市',value:8},
                   {name:'广元市',value:5},
                   {name:'自贡市',value:7},
                   {name:'泸州市',value:2},
                   {name:'乐山市',value:1},
                   {name:'宜宾市',value:0}
               ],
           }
       ]
   },
    /* 柱状图（单色一柱） */
    barOption:{
        tooltip : {
            trigger: 'axis'
        },
        color:['#2684FC'],
        legend: {
            data:['活动组织数量']
        },
        grid: {//设置4周留白
            left: 0,
            right: 0,
            bottom: 0,
            containLabel: true // 确保坐标轴标签也在grid区域内
        },
        calculable : true,
        xAxis : [
            {
                type : 'category',
                data : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
            }
        ],
        yAxis : [
            {
                type : 'value'
            }
        ],
        series : [
            {
                name:'活动组织数量',
                type:'bar',
                barWidth:'40%',
                data:[2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
            }
        ]
    }
}
export {
    allChartTypes
}