import * as echarts from 'echarts';
import chinaMap from '@/utils/china.json';
import sichuanMap from '@/utils/province/sichuan.json';
import { allChartTypes } from '@/utils/echartOption.js';
export default {
  name: "chart",
  // 传入id: 若一个页面出现多个折线图时，id必须不同
  // data: 折线图配置数据
  props: ['chartOptionData'],
  data() {
    return {
      id: '',
      data: ''
    };
  },
  methods: {
    initChart(chartOptionData, type, colors) {
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom);
      const option = allChartTypes[type];
      if (!colors) {
        colors = ['orange', 'yellow'];
      }
      // 配置图表选项和数据
      if (chartOptionData) {
        if (type == 'mapOption') {
          // 四川 地图
          echarts.registerMap('china', {
            geoJSON: chinaMap
          });
          echarts.registerMap('四川', {
            geoJSON: sichuanMap
          });
          option.dataRange.color = colors;
          option.series[0].data = chartOptionData;
        }
        if (type == 'axisOption') {
          //单折线图
          option.xAxis[0].data = chartOptionData.monthList;
          option.series[0].data = chartOptionData.monthPriceList;
        }
        if (type == 'axisOptions') {
          //双折线图
          option.xAxis[0].data = chartOptionData.monthList;
          option.series[0].data = chartOptionData.monthOrderNumList;
          option.series[1].data = chartOptionData.monthOrderMoneyList;
        }
        if (type == 'barOption') {
          //单柱状图
          option.xAxis[0].data = chartOptionData.monthList;
          option.series[0].data = chartOptionData.monthActiveNumList;
        }
        // 使用刚指定的配置项和数据显示图表
        myChart.setOption(option);
      }
    }
  }
};